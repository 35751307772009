import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import { get } from '../../utils/api_helper';
import { Table } from 'react-bootstrap';

const ViewTradeItems = (props) => {
  const [attachments, setAttachments] = useState();
  const [datatable, setDatatable] = React.useState({
    columns: [],
    rows: [],
  });
  useEffect(() => {
    async function getAttachments() {
      await get('/uploads')
        .then((resp) => {
          setAttachments(resp);
        })
        .catch((err) => {
          console.error('Error', err);
        });
    }
    getAttachments();
  }, []);
  return (
    <>
      <SEO title="Tradable Items | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area mt--30">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="title">Tradable Items</h4>
                </div>
              </div>
              <div className="row mt--20 row--15 mb--40">
                <div className="col-lg-12">
                  <Table>
                    <thead>
                      <tr>
                        {datatable.columns.map((item) => (
                          <th>{item.label}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {attachments &&
                        attachments.map((item) => (
                          <tr>
                            {item.name === 'tradeItem' ? (
                              <>
                                <td>
                                  <a
                                    href={
                                      process.env.REACT_APP_SAARC_API +
                                      item.path.substring(11).replace(/\\/g, '/')
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item.path.substring(38)}
                                  </a>
                                </td>
                              </>
                            ) : (
                              ''
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default ViewTradeItems;
