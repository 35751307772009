import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import { FaTimes, MdCancel } from 'react-icons/all';

export default function OrderFailed() {
  return (
    <>
      <SEO title="Order Failed| SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Events Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 mt_dec--30">
                <div className="static-page">
                  <div className="text-center">
                    <MdCancel size={100} color="red" />

                    <h4 className="mt-5">Oh No!! Your order can not be placed at the moment</h4>
                    <p>
                      Sorry for the inconvenience. <br />
                      For more details, please contact us at{' '}
                      <a href={'mailto:info@saarcbazaar.com'}>info@saarcbazaar.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Events Area  */}
        </div>
      </Layout>
    </>
  );
}
