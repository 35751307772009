import React, { useState, useEffect } from 'react';
import BlogClassicData from '../../../data/blog/BlogList.json';
import { FaSpinner } from 'react-icons/fa';
import filters from './Filters';
import EventsListTwo from './EventsListTwo';
import { get } from '../../../utils/api_helper';
import moment from 'moment';
import { CATEGORIES, Cats, getCategoriesFromParent, getParentbyCategory } from '../../../utils';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const alldataBLog = BlogClassicData;
const CategoriesProp = ({ column, StyleVarProp }) => {
  const [getAllItems] = useState(alldataBLog);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [noMorePost, setNoMorePost] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');
  const [visibleItems, setVisibleItems] = useState([]);
  const categories = Cats;
  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, []);

  const handleLoadmorebl = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter((data) => data.text === activeFilter && data.id <= tempCount)
        );
      }
    }
  };
  const [dataApi, setDataApi] = useState([]);
  const [cats, setCats] = useState([]);
  useEffect(() => {
    async function getEvents() {
      await get('/events')
        .then((resp) => {
          let today = new Date().toISOString().slice(0, 10);
          setDataApi(resp.filter((event) => moment(today).isSameOrBefore(event.end)));
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
    getEvents();
  }, []);
  useEffect(() => {
    setCats(getCategoriesFromParent('Apparel & Accessories'));
    console.log('Sub-cats', getCategoriesFromParent('Apparel & Accessories'));
  }, []);
  return (
    <>
      {/*{cats &&*/}
      {/*  cats.map((item) => {*/}
      {/*    return (*/}
      {/*      <div key={item.id}>*/}
      {/*        <a>{item.title}</a>*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}

      <Table>
        <thead>
          <div className="row">
            <div className="col-lg-3">
              <h4>
                <Link>Animals & Pet Supplies</Link>
              </h4>
              {getCategoriesFromParent('Animals & Pet Supplies').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link "
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Apparel & Accessories</Link>
              </h4>
              {getCategoriesFromParent('Apparel & Accessories').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Arts & Entertainment</Link>
              </h4>
              {getCategoriesFromParent('Arts & Entertainment').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Baby & Toddler</Link>
              </h4>
              {getCategoriesFromParent('Baby & Toddler').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Business & Industrial</Link>
              </h4>
              {getCategoriesFromParent('Business & Industrial').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin  cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Cameras & Optics</Link>
              </h4>
              {getCategoriesFromParent('Cameras & Optics').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Electronics</Link>
              </h4>
              {getCategoriesFromParent('Electronics').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Food, Beverages & Tobacco</Link>
              </h4>
              {getCategoriesFromParent('Food, Beverages & Tobacco').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Furniture</Link>
              </h4>
              {getCategoriesFromParent('Furniture').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Hardware</Link>
              </h4>
              {getCategoriesFromParent('Hardware').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
            <div className="col-lg-3">
              <h4>
                <Link>Health & Beauty</Link>
              </h4>
              {getCategoriesFromParent('Health & Beauty').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Home & Garden</Link>
              </h4>
              {getCategoriesFromParent('Home & Garden').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
            <div className="col-lg-3">
              <h4>
                <Link>Luggage & Bags</Link>
              </h4>
              {getCategoriesFromParent('Luggage & Bags').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Mature</Link>
              </h4>
              {getCategoriesFromParent('Mature').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Media</Link>
              </h4>
              {getCategoriesFromParent('Media').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Office Supplies</Link>
              </h4>
              {getCategoriesFromParent('Office Supplies').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Sporting Goods</Link>
              </h4>
              {getCategoriesFromParent('Sporting Goods').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
            <div className="col-lg-3">
              <h4>
                <Link>Vehicles & Parts</Link>
              </h4>
              {getCategoriesFromParent('Vehicles & Parts').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Toys and Games</Link>
              </h4>
              {getCategoriesFromParent('Toys and Games').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Religious & Ceremonial</Link>
              </h4>
              {getCategoriesFromParent('Religious & Ceremonial').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
              <h4>
                <Link>Software</Link>
              </h4>
              {getCategoriesFromParent('Software').map((item) => {
                return (
                  <Link
                    to={
                      '/search?s=&cat=' +
                      item.title.toLowerCase().replace(/\s/g, '-').replace('&', 'and')
                    }
                    className="no-margin cat-link"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </div>
          {/*{categories &&*/}
          {/*  categories.map((item) => {*/}
          {/*    return (*/}
          {/*      <div key={item.id} className="col-lg-4">*/}
          {/*        <tr>*/}
          {/*          {*/}
          {/*            item.parent_id === null ? (*/}
          {/*              <>*/}
          {/*                <h4>*/}
          {/*                  <a>{item.title}</a>*/}
          {/*                  {getCategoriesFromParent(item.title).map((item2) => {*/}
          {/*                    return <p className="no-margin">{item2.title}</p>;*/}
          {/*                  })}*/}
          {/*                </h4>*/}
          {/*              </>*/}
          {/*            ) : null*/}
          {/*            // <a>{item.title}</a>*/}
          {/*          }*/}
          {/*        </tr>*/}
          {/*      </div>*/}
          {/*    );*/}
          {/*  })}*/}
        </thead>
      </Table>
      {/*<div className="col-lg-12">*/}
      {/*  <div className="row row--15">*/}
      {/*    {dataApi.map((item) => (*/}
      {/*      <div key={item.id} className={column}>*/}
      {/*        <EventsListTwo StyleVar={StyleVarProp} data={item} />*/}
      {/*      </div>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="col-lg-12">*/}
      {/*  <div className="rwt-load-more text-center mt--60">*/}
      {/*    <button*/}
      {/*      className="btn btn-default btn-icon"*/}
      {/*      onClick={handleLoadmorebl}*/}
      {/*      disabled={noMorePost ? 'disabled' : null}*/}
      {/*    >*/}
      {/*      {noMorePost ? (*/}
      {/*        'No Post Here'*/}
      {/*      ) : (*/}
      {/*        <span>*/}
      {/*          View*/}
      {/*          <span className="icon">*/}
      {/*            <FaSpinner />*/}
      {/*          </span>*/}
      {/*        </span>*/}
      {/*      )}*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};
export default CategoriesProp;
