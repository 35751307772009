import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import ContactForm from './ContactForm';

const SourcingRequest = (props) => {
  return (
    <>
      <SEO title="Sourcing Request | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area mt--30">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="title">Sourcing Request</h4>
                </div>
              </div>
              <div className="row mt--20 row--15 mb--40">
                <div className="col-lg-12">
                  <ContactForm formStyle="contact-form-1" query={props.location.query} />
                </div>
              </div>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default SourcingRequest;
