import React from 'react';

const CompanyInformation = (props) => {
  const countryTabs = [
    'afghanistan',
    'bangladesh',
    'bhutan',
    'india',
    'maldives',
    'nepal',
    'pakistan',
    'sri lanka',
    'SAFTA Tarriffs',
  ];
  const getTabFromCountry = (country) => {
    return countryTabs.indexOf(country);
  };
  getTabFromCountry('pakistan');
  return (
    <>
      {props && props.country ? (
        <div className="position-relative">
          <ul className="company_desc no-padding">
            <li>
              <a
                href={
                  process.env.REACT_APP_SAARC_DEFAULT_URL +
                  '/economic-statistics?t=' +
                  getTabFromCountry(props.country.toLowerCase())
                }
              >
                Trade Statistics for {props.country}{' '}
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_SAARC_DEFAULT_URL +
                  '/trade-agreements?t=' +
                  getTabFromCountry(props.country.toLowerCase())
                }
              >
                Trade Agreements of {props.country}
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_SAARC_DEFAULT_URL +
                  '/trade-guides?t=' +
                  getTabFromCountry(props.country.toLowerCase())
                }
              >
                Import Export Guide for {props.country}
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_SAARC_DEFAULT_URL +
                  '/top-10s?t=' +
                  getTabFromCountry(props.country.toLowerCase())
                }
              >
                Top 10 Imports Exports of {props.country}
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_SAARC_DEFAULT_URL +
                  '/trade-agreements?t=9' +
                  getTabFromCountry(props.country.toLowerCase())
                }
              >
                SAFTA Tarriffs
              </a>
            </li>
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default CompanyInformation;
