import React, { useEffect, useState } from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import SearchPage from '../components/blog/SearchPage';
import { get } from '../utils/api_helper';
import { useLocation } from 'react-router-dom';
import { COMPANY_STATUS, PRODUCT_STATUS } from '../utils';

const Search = () => {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const [listingsData, setListingsData] = useState([]);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    async function getListings() {
      await get('/listings')
        .then((resp) => {
          setListingsData(
            resp.filter(
              (product) =>
                product.status === PRODUCT_STATUS.APPROVED &&
                product.state === true &&
                product.Company.state === true &&
                (product.Company.status === COMPANY_STATUS.APPROVED ||
                  product.Company.status === COMPANY_STATUS.PUPDATE)
            )
          );
        })
        .catch((err) => {
          console.error('error getting listings', err);
        });
    }
    getListings();
  }, []);
  useEffect(() => {
    async function getCompanies() {
      await get('/companies')
        .then((resp) => {
          setCompanies(
            resp.filter(
              (company) =>
                (company.status === COMPANY_STATUS.APPROVED ||
                  company.status === COMPANY_STATUS.PUPDATE) &&
                company.state === true
            )
          );
        })
        .catch((err) => {
          console.error('error getting companies', err);
        });
    }
    getCompanies();
  }, []);

  return (
    <>
      <SEO title={`Search results for ${query.get('s')}`} />
      <Layout>
        <SearchPage
          listings={listingsData}
          setListings={setListingsData}
          companies={companies}
          option={query.get('option')}
          search={query.get('s')}
          cat={query.get('cat')}
          parentCat={query.get('pcat')}
          bCat={query.get('bCat')}
          // parentCat={'Food, Beverages & Tobacco'}
        />
      </Layout>
    </>
  );
};

export default Search;
