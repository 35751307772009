import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import EventsProp from './itemProp/EventsProp';
import CategoriesProp from './itemProp/CategoriesProp';

const Categories = () => {
  return (
    <>
      <SEO title="Categories | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Events Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--30">
                <CategoriesProp
                  column="col-lg-6 mt--30"
                  StyleVarProp="box-card-style-default card-list-view"
                />
              </div>
            </div>
          </div>
          {/* End Events Area  */}
        </div>
      </Layout>
    </>
  );
};

export default Categories;
