import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Table } from 'react-bootstrap';
import { GoFilePdf } from 'react-icons/all';
import { useLocation } from 'react-router-dom';
const TradeAgreements = () => {
  const pdf = [
    'Afghanistan',
    'Bangladesh',
    'Bhutan',
    'India',
    'Maldives',
    'Nepal',
    'Pakistan',
    'Sri Lanka',
    'SAFTA Tariffs',
  ];
  const documents = {
    Afghanistan: [
      {
        link: 'PAK-AFG(APTTA)',
        title: 'Pakistan Afghanistan APTTA',
      },
    ],
    Bangladesh: [
      {
        link: 'Bhutan Bnagladesh Trade agreement',
        title: 'Bhutan Bangladesh Trade Agreement',
      },
      {
        link: 'MOC India_and_Bangladesh_8th_April_2017',
        title: 'MOC India and Bangladesh 8th April 2017',
      },
      {
        link: 'MOU Border Haats across Border India and Bangladesh2010',
        title: 'MOU Border Haats across Border India and Bangladesh 2010',
      },
      {
        link: 'Preferential-Trade-Agreement-between Bhutan and Bangladesh-2020',
        title: 'Preferential Trade Agreement Between Bhutan and Bangladesh',
      },
      {
        link: 'PROTOCOL TO The AGREEMENT ON TRADE Bangladesh Bhutan',
        title: 'Protocol to the agreement on the trade Bangladesh Bhutan',
      },
      {
        link: 'Regional Trade Agreement (RTA) Policy',
        title: 'Regional Trade Agreement (RTA) Policy',
      },
    ],
    Bhutan: [
      {
        link: 'AGREEMENT India Bhutan',
        title: 'Bhutan Bangladesh Trade Agreement',
      },
      {
        link: 'Bhutan Bnagladesh Trade agreement',
        title: 'MOC India and Bangladesh 8th April 2017',
      },
      {
        link: 'MOC India_Bhutan_12th_Nov_2016',
        title: 'MOC India_Bhutan_12th_Nov_2016',
      },
      {
        link: 'Preferential-Trade-Agreement-between Bhutan and Bangladesh-2020',
        title: 'Preferential Trade Agreement Between Bhutan and Bangladesh',
      },
      {
        link: 'PROTOCOL TO The AGREEMENT ON TRADE Bangladesh Bhutan',
        title: 'Protocol to the agreement on the trade Bangladesh Bhutan',
      },
      {
        link: 'TRADE, COMMERCE AND TRANSIT India Bhutan',
        title: 'Trade, commerce and transit India Bhutan',
      },
    ],
    India: [
      {
        link: '/AGREEMENT India Bhutan',
        title: 'AGREEMENT India Bhutan',
      },
      {
        link: 'Free Trade Agreements Frequently Asked Questions (FAQs)',
        title: 'Free Trade Agreements Frequently Asked Questions (FAQs)',
      },
      {
        link: 'INDIA - SRI LANKA FTA',
        title: 'INDIA - SRI LANKA FTA',
      },
      {
        link: 'Indo nepal treaty of trade Revised',
        title: 'Indo nepal treaty of trade Revised',
      },
      {
        link: 'MOC India_and_Bangladesh_8th_April_2017',
        title: 'MOC India_and_Bangladesh_8th_April_2017',
      },
      {
        link: 'MOC India_Bhutan_12th_Nov_2016',
        title: 'MOC India Bhutan 12th Nov 2016',
      },
      {
        link: 'MOU Border Haats across Border India andBangladesh2010',
        title: 'MOU Border Haats across Border India andBangladesh2010',
      },
      {
        link: 'Trade Agreement India-Maldives',
        title: 'Trade Agreement India-Maldives',
      },
      {
        link: 'TRADE, COMMERCE AND TRANSIT India Bhutan',
        title: 'TRADE, COMMERCE AND TRANSIT India Bhutan',
      },
      {
        link: 'TransitTreaty_NEPAL',
        title: 'TransitTreaty_NEPAL',
      },
    ],
    Maldives: [
      {
        link: 'Trade Agreement India-Maldives',
        title: 'Trade Agreement India-Maldives',
      },
    ],

    Nepal: [
      {
        link: 'Indo nepal treaty of trade Revised',
        title: 'Indo nepal treaty of trade Revised',
      },
      {
        link: 'Nepal trade Agreements',
        title: 'Nepal trade Agreements',
      },
      {
        link: 'TransitTreaty_NEPAL',
        title: 'TransitTreaty_NEPAL',
      },
    ],
    Pakistan: [
      {
        link: 'PAK AFG (APTTA)',
        title: 'PAK AFG (APTTA)',
      },
      {
        link: 'Pakistan (Draft-Offer-List-of-Pakistan-for-SAFTA)',
        title: 'Pakistan (Draft-Offer-List-of-Pakistan-for-SAFTA)',
      },
      {
        link: 'Pakistan (Draft-Request-List-of-Pak-for-Bangladesh)',
        title: 'Pakistan (Draft-Request-List-of-Pak-for-Bangladesh)',
      },
      {
        link: 'Pakistan (Draft-Request-List-of-Pak-for-India)',
        title: 'Pakistan (Draft-Request-List-of-Pak-for-India)',
      },
      {
        link: 'Pakistan (Draft-Request-List-of-Pak-for-S-Lanka)',
        title: 'Pakistan (Draft-Request-List-of-Pak-for-S-Lanka)',
      },
      {
        link: 'PSFTA anx-A-1',
        title: 'PSFTA anx-A-1',
      },
      {
        link: 'PSFTA anx-A-2',
        title: 'PSFTA anx-A-2',
      },
      {
        link: 'PSFTA anx-A-3',
        title: 'PSFTA anx-A-3',
      },
      {
        link: 'PSFTA anx-A-4_2',
        title: 'PSFTA anx-A-4_2',
      },
      {
        link: 'PSFTA anx-B-1',
        title: 'PSFTA anx-B-1',
      },
      {
        link: 'PSFTA anx-B-3_2',
        title: 'PSFTA anx-B-3_2',
      },
      {
        link: 'PSFTA Pakistan Sri Lanka  FTA Text',
        title: 'PSFTA Pakistan Sri Lanka  FTA Text',
      },
    ],
    'Sri Lanka': [
      {
        link: 'INDIA - SRI LANKA FTA',
        title: 'INDIA - SRI LANKA FTA',
      },
      {
        link: 'PSFTA Pakistan Sri Lanka  FTA Text',
        title: 'PSFTA Pakistan Sri Lanka  FTA Text',
      },
      {
        link: 'Sri Lanka trade Agreements',
        title: 'Sri Lanka trade Agreements',
      },
    ],
    'SAFTA Tariffs': [
      {
        link: 'SAARC AGREEMENT ON TRADE IN SERVICES (SATIS)',
        title: 'SAARC AGREEMENT ON TRADE IN SERVICES (SATIS)',
      },
      {
        link: "SAFTA Afghanistan's _Revised_Sensitive_List_(Phase-II)",
        title: "SAFTA Afghanistan's _Revised_Sensitive_List_(Phase-II)",
      },
      {
        link: 'SAFTA Agreement',
        title: 'SAFTA Agreement',
      },
      {
        link: 'SAFTA Annex-II of SAFTA Agreement -Technical Assistance for LDCs',
        title: 'SAFTA Annex-II of SAFTA Agreement -Technical Assistance for LDCs',
      },
      {
        link: 'SAFTA Annex-III of SAFTA Agreement -  MCRL',
        title: 'SAFTA Annex-III of SAFTA Agreement -  MCRL',
      },
      {
        link: 'SAFTA Annex-IV of SAFTA Agreement - SAFTA Rules of Origin',
        title: 'SAFTA Annex-IV of SAFTA Agreement - SAFTA Rules of Origin',
      },
      {
        link: "SAFTA Bangladesh's_Sensitive_List_(Phase-II)",
        title: "SAFTA Bangladesh's_Sensitive_List_(Phase-II)",
      },
      {
        link: 'SAFTA Final-Sensitive-Lists-SAFTA',
        title: 'SAFTA Final-Sensitive-Lists-SAFTA',
      },
      {
        link: "SAFTA India's_Revised_Sensitive_List_(Phase-II)",
        title: "SAFTA India's_Revised_Sensitive_List_(Phase-II)",
      },
      {
        link: "SAFTA Maldive's_Revised_Sensitive_List_(Phase-II)",
        title: "SAFTA Maldive's_Revised_Sensitive_List_(Phase-II)",
      },
      {
        link: "SAFTA Nepal's_Revised_Sensitive_List_(Phase-II)",
        title: "SAFTA Nepal's_Revised_Sensitive_List_(Phase-II)",
      },
      {
        link: 'SAFTA OCPs _Anx-B of Annex-IV',
        title: 'SAFTA OCPs _Anx-B of Annex-IV',
      },
      {
        link: 'SAFTA Pakistan-Revised-Sensitive-List-under-SAFTA',
        title: 'SAFTA Pakistan-Revised-Sensitive-List-under-SAFTA',
      },
      {
        link: 'SAFTA Product Specific Rules _Anx A of Annex-IV',
        title: 'SAFTA Product Specific Rules _Anx A of Annex-IV',
      },
      {
        link: "SAFTA SriLanka's_Revised_Sensitive_List_(Phase-II)",
        title: "SAFTA SriLanka's_Revised_Sensitive_List_(Phase-II)",
      },
      {
        link: 'SAPTA Agreement',
        title: 'SAPTA Agreement',
      },
    ],
  };
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  return (
    <>
      <SEO
        title="Trade Agreements"
        description="Get to know more about Trade Agreements from SAARC Countries"
      />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area mt--30 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Tabs defaultIndex={query.get('t') ? query.get('t') : 0}>
                    <div className="row row--30 align-items-center">
                      <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="rn-default-tab">
                          <div className="tab-button-panel">
                            <TabList className="tab-button react-tabs__tab">
                              {pdf.map((item, index) => {
                                return (
                                  <Tab key={index}>
                                    <div className="rn-tab-button">
                                      <button>{item}</button>
                                    </div>
                                  </Tab>
                                );
                              })}
                            </TabList>
                          </div>

                          <div className="tab-content-panel">
                            {pdf.map((name, index) => {
                              return (
                                <TabPanel key={index}>
                                  <div className="rn-tab-content">
                                    <div className="inner">
                                      <div className="col-lg-12">
                                        <div className="rn-contact-address mt_dec--30">
                                          <Table striped bordered hover>
                                            <tbody>
                                              {Object.keys(documents).map((item) => {
                                                if (item !== name) return;
                                                return documents[item].map((elem) => {
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td>
                                                          <a
                                                            href={
                                                              '../../../documents/Agreements/' +
                                                              name +
                                                              '/' +
                                                              elem.link +
                                                              '.pdf'
                                                            }
                                                            target="_blank"
                                                            className="color-black"
                                                          >
                                                            {elem.title}
                                                          </a>
                                                        </td>
                                                        <td>
                                                          <a
                                                            href={
                                                              '../../../documents/Agreements/' +
                                                              name +
                                                              '/' +
                                                              elem.link +
                                                              '.pdf'
                                                            }
                                                            target="_blank"
                                                          >
                                                            <GoFilePdf color="red" size={20} />
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                });
                                              })}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default TradeAgreements;
