import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ALL_COUNTRIES } from '../../utils';
import Select from 'react-select';

const SouringRequestForm = () => {
  const [data, setData] = useState({});
  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChange = (value, action) => {
    if (!value) {
      setData({ ...data, [action.name]: '' });
    } else {
      setData({ ...data, [action.name]: value.label });
    }
  };
  const bg_image = './images/split/sourcing.jpg';
  return (
    <div className="container">
      <div className="mb-5 easy-sourcing container" id="sourcings">
        <div
          style={{ backgroundImage: `url(${bg_image})` }}
          className="sourcing-bg   d-lg-block "
        />
        <div className="sourcing-content ">
          <div className="sourcing-text ">
            <div className="col-lg-6 col-sm-12 sourcing-form">
              <h6 className="form-title">Want to get quotations?</h6>
              <div className="form-group mx-auto">
                <div className="form-text-input mb--15">
                  <input
                    type="text"
                    className="col-12 "
                    name="product"
                    placeholder="What are you looking for?"
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="mb-4 ">
                  <Select
                    menuPortalTarget={document.querySelector('body')}
                    placeholder="Destination"
                    name="country"
                    className="mb-4 "
                    onChange={handleChange}
                    options={ALL_COUNTRIES}
                  />
                </div>

                <div className="col-lg-12">
                  <div className="text-center">
                    <Link to={{ pathname: '/view-sourcing-request', query: { data: data } }}>
                      <button className="btn-default btn-small mr-md-5 mb-3">View Requests</button>
                    </Link>{' '}
                    <Link to={{ pathname: '/add-sourcing-request', query: { data: data } }}>
                      <button className="btn-default btn-small">Send Request</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 d-md-none d-xs-none d-sm-none d-xl-block  d-lg-block">
              <div className="sourcing-title"> Sourcing Request</div>
              <div className="sourcing-desc">
                <p>
                  Can’t find something? Tell us what you need. <br />
                  Post your requirements and let suppliers come to you.
                  <br />
                  Sourcing Made Easy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SouringRequestForm;
