import React, { useState } from 'react';

const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. SAARC Bazaar will contact you as soon as possible.
    </p>
  );
};

function ContactForm({ props, formStyle }) {
  const [result, showresult] = useState(false);

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="">
      <div className="form-group">
        <input type="text" name="fullname" placeholder="Your Name" required />
      </div>

      <div className="form-group">
        <input type="email" name="email" placeholder="Email Address" required />
      </div>

      <div className="form-group">
        <input type="text" name="phone" placeholder="Phone Number" required />
      </div>

      <div className="form-group">
        <input type="text" name="subject" placeholder="Subject" required />
      </div>

      <div className="form-group">
        <textarea name="message" placeholder="Your Message" required></textarea>
      </div>

      <div className="form-group">
        <button className="btn-default btn-large">Submit Now</button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
