import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
const TermsAndConditions = () => {
  return (
    <>
      <SEO title="Terms and Conditions | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Events Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 mt_dec--30">
                <div className="static-page">
                  <h3 className="text-center">Terms and Conditions</h3>
                  <p>
                    By clicking ‘I agree’ users agree to acknowledge the terms and conditions stated
                    and agree to be bound by the Terms and Conditions contained in this Agreement.
                    This Agreement may be subject to change or update from time to time without any
                    prior notice to users. The new Terms and Conditions will attain validity once
                    they are posted on SAARCBazaar.com.
                  </p>
                  <p>
                    Continuous usage of service expresses your acceptance of the changed Agreement.
                    SAARC Bazaar has copyrights over all related information and web designs. Any
                    unauthorized copying or commercial use of content obtained from SAARC Bazaar is
                    strictly prohibited, and SAARC Bazaar reserves the right to take legal action in
                    the case of such activity.
                  </p>
                  <p>SAARC Bazaar does not guarantee itself to be error-free or uninterrupted.</p>
                  <h4>I agree to/that:</h4>
                  <ul>
                    <li>
                      Behave in accordance with applicable laws. Hence, SAARC Bazaar will not be
                      responsible for possible consequences caused by the user's behavior while
                      using SAARC Bazaar.
                    </li>
                    <li>
                      SAARC Bazaar may, in its discretion, refuse service to any user at any time
                      without prior notice.{' '}
                    </li>
                    <li>
                      SAARC Bazaar may, in its discretion, suspend membership to any user at any
                      time without prior notice.
                    </li>
                    <li>
                      Provide true, accurate, current and complete information. Promptly update
                      content to keep it true current and complete.
                    </li>
                    <li>
                      If the information provided by me is untrue, inaccurate and not current or
                      incomplete, SAARC Bazaar may suspend or terminate Service without prior
                      notice.
                    </li>
                    <li>
                      By becoming a registered user of SAARC Bazaar, I will be able to access my own
                      account with a Username and Password.
                    </li>
                    <li>
                      By becoming a registered user of SAARC Bazaar, I will be put on the mailing
                      list of SAARC Bazaar. Hence, SAARC Bazaar is entitled to send all kinds of
                      relevant business information and contact me on any of the contact provided by
                      myself. I will contact SAARC Bazaar if I refuse to continue receiving such
                      information or do not want to be contacted.{' '}
                    </li>
                    <li>
                      I am responsible for maintaining the confidentiality of my Username and
                      Password and thus, am fully responsible for all the activities that occur
                      under my User Profile.
                    </li>
                    <li>
                      I shall notify SAARC Bazaar of any unauthorized usage of my User Profile or
                      any breach of its security. Hence, SAARC Bazaar will not be liable for any
                      loss or damage that may result from failure to comply with this Agreement.{' '}
                    </li>
                    <li>
                      Use the service provided by SAARC Bazaar only for purposes permitted in the
                      Agreement, applicable law, regulations, or guidelines of the relevant
                      jurisdiction.
                    </li>
                    <li>
                      I am solely responsible for any breach of my obligations under this Agreement.
                    </li>
                    <li>
                      I am solely and legally responsible for (and that SAARC Bazaar has no
                      responsibility) for the accuracy, completeness, integrity, timeliness,
                      correctness, and non-infringement of the information that users create,
                      display, transmit or provide while and for the consequences of users actions.
                    </li>
                    <li>
                      Information provided by me shall not
                      <ol>
                        <li>
                          be obscene, contain, infer any pornography, sex-related merchandising, any
                          other content, otherwise promotes explicit sexual materials or is
                          otherwise harmful to people
                        </li>
                        <li>
                          be defamatory, libelous, unlawfully threatening or unlawfully harassing
                        </li>
                        <li>
                          promote discrimination based on race, sex, religion, nationality,
                          disability, sexual orientation or age
                        </li>
                        <li>
                          infringe or otherwise abet, encourage the infringement ,be in violation of
                          any third party's copyright, patent, trademarks, trade secrets or other
                          proprietary rights, rights of publicity or privacy, or any other Third
                          Party Rights, and{' '}
                        </li>
                        <li> be restricted or prohibited by law.</li>
                      </ol>
                    </li>
                    <li>
                      SAARC Bazaar may collect information about users, companies and build its own
                      database.
                    </li>
                    <li>I shall use SAARC Bazaar for lawful purposes only.</li>
                    <li>
                      I expressly agree that the use of SAARC Bazaar.com is at my own risk and SAARC
                      Bazaar is not responsible for the content found on the database.
                    </li>
                    <li>
                      I expressly agree that SAARC Bazaar disclaims all warranties of any kind.{' '}
                    </li>
                    <li>
                      I acknowledge and agree that if any material, and/or data downloaded or
                      obtained from the database on SAARC Bazaar is at my own discretion and risk.
                      Hence, I will be solely responsible for any damages to my computer system or
                      loss of data that result from the download of such material and/or data.
                    </li>
                    <li>
                      I acknowledge and agree that SAARC Bazaar, SAARC Chamber, SAARC Secretariat,
                      its officers, directors, agents, employees, teams shall not be liable to me or
                      anyone else for any loss, damage, injury, accident or any unfortunate event
                      that may arise from using SAARC Bazaar, or for having relied on content or
                      access provided by SAARC Bazaar for any dealing or decisions.
                    </li>
                  </ul>
                  <p>
                    You may stop using the service provided by SAARC Bazaar.com at any time by
                    contacting us at <a href="mailto:info@saarcbazaar.com">info@saarcbazaar.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End Events Area  */}
        </div>
      </Layout>
    </>
  );
};

export default TermsAndConditions;
