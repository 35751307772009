import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
const TermsAndConditions = () => {
  return (
    <>
      <SEO title="Disclaimer | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Events Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 mt_dec--30">
                <div className="static-page">
                  <h3 className="text-center">Disclaimer</h3>
                  <h4>Data on SAARC Bazaar</h4>
                  <p>
                    SAARC Bazaar publishes information that originates from government and relevant
                    sources. We try to make sure that all content on the website is accurate and
                    relevant. However, SAARC Bazaar cannot guarantee the accuracy and relevancy of
                    data. Please seek the advice of appropriate professionals regarding any
                    information or products displayed on SAARC Bazaar.
                  </p>
                  <p>
                    The information on this website contains no legal value. Hence, SAARC Bazaar is
                    in no way liable for any consequences that may arise for the usage of this data.
                    Furthermore, SAARC Bazaar is also not responsible for the data published on
                    linked websites and does not guarantee that the links provided will always work
                    correctly. The linked websites are property of other parties and subsequently
                    governed by their own terms and conditions.
                  </p>
                  <h4>Member Data</h4>
                  <p>
                    On receiving user information, we try our best to ensure the stability and
                    security of SAARC Bazaar’s systems. However, it should be noted that no
                    transmission can be guaranteed as 100% secure from breaches and hacker attacks.
                    Therefore, SAARC Bazaar is not liable for any breach of security and all
                    information provided to SAARC Bazaar is at the user’s own discretion and risk.
                  </p>
                  <p>
                    Users are strongly recommended to keep usernames and passwords on SAARC Bazaar
                    safe and secure. To ensure the safety and security of your company and personal
                    information please do not disclose your password to others. If you feel that
                    your Username or password is for any reason at risk, please contact us
                    immediately, or change the password.{' '}
                  </p>
                  <p>Please note that SAARC Bazaar currently does not support payments. </p>
                  <h4>How to contact SAARC Bazaar?</h4>
                  <p>
                    Do you have any suggestions on our Privacy Policy, please contact us at{' '}
                    <a href="mailto:info@saarcbazaar.com">info@saarcbazaar.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End Events Area  */}
        </div>
      </Layout>
    </>
  );
};

export default TermsAndConditions;
