import React, { useState } from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import { getUserId } from '../../utils';
import * as api from '../../utils/api_helper';
import { Alert, Button, Spinner } from 'react-bootstrap';

const Result = (props) => {
  return (
    <>
      <Alert variant="success">
        Thank you for contacting SAARC Bazaar. We will get back to you soon. If you wish to submit
        another query please click the button below.
      </Alert>
      <div className="text-center">
        <Button onClick={() => props.setResult(false)} className="btn-default btn-sm">
          Submit Another Query
        </Button>
      </div>
    </>
  );
};

function ContactUsForm() {
  const [result, setResult] = useState(false);
  const [formData, setFormData] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .post('listings/contactus', formData)
      .then((resp) => {
        setResult(true);
        window.scroll(0, 0);
        setLoading(false);
      })
      .catch((e) => {
        console.error(`Failed to add sourcing`, e);
        setLoading(false);
        setError(true);
      });
  };
  const Error = () => {
    return <p className="text-danger">Please try again later</p>;
  };
  return (
    <React.Fragment>
      <div className="form-group">
        {result ? (
          <Result setResult={setResult} />
        ) : (
          <form onSubmit={handleSubmit}>
            <SectionTitle title="Contact Us" textAlign="text-center" />
            <p>
              Please fill the contact form below and one of our representatives will get back to you
              on the provided email/phone as soon as possible.
            </p>
            <div className="row">
              <div className="col-lg-4 form-group">
                <input
                  onChange={onChange}
                  type="text"
                  name="fullname"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="col-lg-4 form-group">
                <input
                  onChange={onChange}
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required
                />
              </div>
              <div className="col-lg-4 form-group">
                <input
                  onChange={onChange}
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 form-group">
                <input
                  onChange={onChange}
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12  form-group">
                <textarea onChange={onChange} name="message" placeholder="Your Message" required />
              </div>
            </div>
            <div className="mb-5 text-center form-group">
              <Button type="submit" className="btn-default btn-sm" disabled={loading}>
                {loading ? (
                  <>
                    <Spinner animation="border" size="md" /> Please Wait
                  </>
                ) : (
                  <> Submit Now</>
                )}
              </Button>
            </div>
          </form>
        )}
      </div>
      <div className="form-group">{error ? <Error /> : null}</div>
    </React.Fragment>
  );
}
export default ContactUsForm;
