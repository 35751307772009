import React, { useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import * as api from '../../utils/api_helper';
import { Alert, Button, Spinner } from 'react-bootstrap';

const Result = () => {
  return (
    <Alert key="success" variant="success">
      Thank you for contacting SAARC Bazaar. We will get back to you soon. <br />
      If you wish to submit another query, please click the button below!
    </Alert>
  );
};
const Preview = () => {
  return (
    <Alert key="success" variant="danger">
      This is only a product preview. You can not submit any forms here.
    </Alert>
  );
};

function QueryForm(props) {
  const [result, showresult] = useState(false);
  const [queryOption, setQueryOption] = useState({ formType: '' });
  const [previewMessage, setPreviewMessage] = useState(false);
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);

  const handleQuerySubmit = (e) => {
    e.preventDefault();
    if (props && props.preview) {
      setPreviewMessage(true);
    } else {
      formData.product_URL =
        process.env.REACT_APP_SAARC_DEFAULT_URL + '/product-details/' + props.productData.id;
      formData.company_ID = props.company.id;
      formData.company_name = props.company.name;
      formData.product_name = props.productData.title;
      formData.company_user_id = props.company.UserId;
      setLoading(true);
      api
        .post('listings/queryemail', formData)
        .then((resp) => {
          showresult(true);
          setFormData('');
          setLoading(false);
        })
        .catch((e) => {
          console.error(`Failed to add sourcing`, e);
          setLoading(false);
        });
    }
  };
  const handleChange = (value, action) => {
    setFormData('');
    if (!value) {
      setQueryOption({ ...queryOption, [action.name]: '' });
    } else {
      setQueryOption({ ...queryOption, [action.name]: value.value });
    }
  };
  const options = [
    { value: 'generalQuery', label: 'General Query' },
    { value: 'orderQuery', label: 'Order Query' },
  ];
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <React.Fragment>
      <div>
        {result ? (
          <>
            <Result />
            <div className="text-center">
              <Button
                type="submit"
                className="btn-default btn-small"
                onClick={() => showresult(false)}
              >
                Submit Another Query
              </Button>
            </div>
          </>
        ) : (
          <>
            <div>{previewMessage ? <Preview /> : null}</div>
            <label>Please select the type of form you want to proceed with</label>
            <div className="row">
              <div className="col-lg-12 mb-4">
                <Select
                  placeholder="Form Type"
                  name="formType"
                  onChange={handleChange}
                  options={options}
                  defaultValue="generalQuery"
                />
              </div>
            </div>
            {queryOption.formType === 'generalQuery' ? (
              <>
                <form onSubmit={handleQuerySubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="query"
                          value={formData.query || ''}
                          placeholder="Please enter your question..."
                          onChange={onChange}
                          required
                        />
                        <input
                          type="text"
                          name="emailUser"
                          className="mt-4"
                          value={formData.emailUser|| ''}
                          placeholder="Please enter your email..."
                          onChange={onChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          onChange={onChange}
                          name="details"
                          value={formData.details || ''}
                          placeholder="Any relevant details..."
                          required
                        />

                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <Button type={'submit'} className="btn-default btn-small" disabled={loading}>
                      {!loading ? (
                        'Submit Now'
                      ) : (
                        <>
                          Please Wait <Spinner size="md" animation="border" role="status" />
                        </>
                      )}
                    </Button>
                  </div>
                </form>
              </>
            ) : (
              <>
                {queryOption.formType === 'orderQuery' ? (
                  <>
                    <form onSubmit={handleQuerySubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              onChange={onChange}
                              type="text"
                              value={formData.quantity || ''}
                              name="quantity"
                              placeholder="Quantity"
                              required
                            />
                            <input
                              onChange={onChange}
                              type="text"
                              value={formData.emailUser || ''}
                              name="emailUser"
                              placeholder="Email"
                              className="mt-4"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <input
                              onChange={onChange}
                              type="text"
                              value={formData.shipping_method || ''}
                              name="shipping_method"
                              placeholder="Preferred Shipping Method"
                              required
                            />
                          </div>
                          <div className="form-group">
                            <input
                              onChange={onChange}
                              className="col-lg-6"
                              type="text"
                              name="trade_items"
                              placeholder="Preferred Trade Items"
                              value={formData.trade_items || ''}
                              required
                            />
                            <input
                              onChange={onChange}
                              className="col-lg-6"
                              type="date"
                              name="delivery_date"
                              value={formData.delivery_date || ''}
                              required
                              min={moment().format('YYYY,MM,DD')}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <textarea
                              onChange={onChange}
                              name="message"
                              value={formData.message || ''}
                              placeholder="Your Message"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group text-center">
                        <Button type="submit" className="btn-default btn-small">
                          {!loading ? (
                            'Submit Now'
                          ) : (
                            <>
                              Please Wait <Spinner size="md" animation="border" role="status" />
                            </>
                          )}
                        </Button>
                      </div>
                    </form>
                  </>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
}
export default QueryForm;
