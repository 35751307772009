import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import SectionTitle from '../sectionTitle/SectionTitle';
import ContactUsForm from './ContactUsForm';

const ContactUs = () => {
  return (
    <>
      <SEO
        title="Contact Us"
        description="Having any queries or problems exploring SAARC Bazaar? Contact us for more information and problem resolution"
      />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <SectionTitle title="Contact Us" />
          <div className="rwt-contact-area ">
            <div className="container">
              <div className="offset-2 col-lg-8">
                <ContactUsForm formStyle="contact-form-1" />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ContactUs;
