import React from 'react';
import { slugify } from '../../../utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const EventListTwo = ({ data, StyleVar }) => {
  // const cate = data.categories.map((value, i) => {
  //     return (
  //         <Link to={process.env.PUBLIC_URL + `/category/${slugify(value)}`} key={i}>{value}{i !== data.categories.length - 1 && ","}</Link>
  //     )
  // });

  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="content">
          <h4 className="title">
            {/*<Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>*/}
            {data.title}
            {/*</Link>*/}
          </h4>
          <ul className="rn-meta-list">
            {/* <li><Link to={process.env.PUBLIC_URL + `/archive/${slugify(data.author)}`}>{data.author}</Link></li> */}
            <li className="separator">{data.location}</li>
            <li className="separator">Start Date: {data.start}</li>
            <li className="separator">End Date: {data.end}</li>

            {/* <li>{data.date}</li> */}
          </ul>
          {/* <p className="descriptiion">{data.excerpt}</p> */}
        </div>
      </div>
    </div>
  );
};
EventListTwo.propTypes = {
  data: PropTypes.object,
};
export default EventListTwo;
