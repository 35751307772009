import React, { useState, useEffect } from 'react';
import ProductsList from '../../data/products/ProductsList.json';

import ProductItem from './ProductItem';
import { FaSpinner } from 'react-icons/fa';
import { get } from '../../utils/api_helper';
import { COMPANY_STATUS, PRODUCT_STATUS } from '../../utils';
import axios from 'axios';

const filters = [
  {
    id: 1,
    text: 'All',
  },
  {
    id: 2,
    text: 'design',
  },
  {
    id: 3,
    text: 'art',
  },
  {
    id: 4,
    text: 'development',
  },
];

const alldata = ProductsList;
const ProductsData = (props) => {
  const [getAllItems] = useState(alldata);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(6);
  const [noMorePost, setNoMorePost] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');
  const [visibleItems, setVisibleItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [products, setProducts] = useState([]);
  const [cur, setCur] = useState(localStorage.getItem('cur'));

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, []);
  useEffect(() => {
    const getProducts = () => {
      let tempCount = dataVisibleCount + dataIncrement;
      if (dataVisibleCount >= getAllItems.length) {
        setHasMore(true);
      }
    };
    getProducts();
  }, [props]);
  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter((data) => data.category === activeFilter && data.id <= tempCount)
        );
      }
    }
  };
  
  async function convertCurrency(product) {
    await axios
      .get(
        'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/' +
          product.currency +
          '.json'
      )
      .then((res) => {
        const usdEquivalent =
          res.data[product.currency][cur.toLowerCase()] * parseFloat(product.price);
        product.price = usdEquivalent.toFixed(2);
      })
      .catch((err) => {
        console.error('Error fetching currency conversion', err);
      });
    
  }

  useEffect(() => {
    if (props && props.companyId) {
      // Get companys other products, to be displayed on company page
      async function getProducts() {
        await get('/listings/company/' + props.companyId)
          .then((resp) => {
            resp &&
              resp.map(async (item) => {
                // convert currency for all companys products
                if (item?.currency && item?.currency != '') {
                  await convertCurrency(item);
                }
              });
            setProducts(resp);
          })
          .catch((err) => {
            console.error('error fetching listings by company id', err);
          });
      }
      getProducts();
    } else {
      // If this component is rendered on the search page, do the action below
      setProducts(props.filtered);
    }
  }, [props.companyId, props.filtered]);
  return (
    <>
      {products && products.length > 0 ? (
        <>
          <div className="row row--5">
            {products.map((item) => (
              <>
                {(item.status === PRODUCT_STATUS.APPROVED ||
                  item.status === COMPANY_STATUS.PUPDATE) &&
                item.featured ? (
                  <>
                    {item.Company &&
                    item.Company.status === COMPANY_STATUS.APPROVED &&
                    item.Company.state === true ? (
                      <div key={item.id} className={props.Column}>
                        <ProductItem portfolio={item} />
                      </div>
                    ) : (
                      <div key={item.id} className={props.Column}>
                        <ProductItem portfolio={item} />
                      </div>
                    )}
                  </>
                ) : null}
              </>
            ))}
            {products.map((item) => (
              <>
                {(item.status === PRODUCT_STATUS.APPROVED ||
                  item.status === PRODUCT_STATUS.PUPDATE) &&
                item.state === true &&
                !item.featured ? (
                  <>
                    {item.Company &&
                    item.Company.status === COMPANY_STATUS.APPROVED &&
                    item.Company.state === true ? (
                      <div key={item.id} className={props.Column}>
                        <ProductItem portfolio={item} />
                      </div>
                    ) : (
                      <div key={item.id} className={props.Column}>
                        <ProductItem portfolio={item} />
                      </div>
                    )}
                  </>
                ) : null}
              </>
            ))}
          </div>
          {!hasMore ? (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div className="rwt-load-more text-center mt--50">
                    <button
                      className="btn btn-default btn-small btn-icon"
                      onClick={handleLoadmore}
                      disabled={noMorePost ? 'disabled' : null}
                    >
                      {noMorePost ? (
                        'No Item Here'
                      ) : (
                        <span>
                          Load More
                          <span className="icon">
                            <FaSpinner />
                          </span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <div className="mb--100 text-center">No Products Available</div>
      )}
    </>
  );
};

export default ProductsData;
