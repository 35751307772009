import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Table } from 'react-bootstrap';
import { BsFileEarmarkExcelFill, GoFilePdf } from 'react-icons/all';
import { useLocation } from 'react-router-dom';
const EconomicStats = () => {
  const pdf = [
    'Afghanistan',
    'Bangladesh',
    'Bhutan',
    'India',
    'Maldives',
    'Nepal',
    'Pakistan',
    'Sri Lanka',
    'Socioeconomic Data',
  ];
  const documents = {
    Afghanistan: [
      {
        link: 'Afghanistan Export countries',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan Exports to India',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan Exports to Pakistan',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan exports to SAARC Commodities',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan Exports to SAARC',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan Imports countries',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan Imports HS2',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan Imports HS6',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan Exports HS2',
        type: '.xlsx',
      },
      {
        link: 'Afghanistan Exports HS6',
        type: '.xlsx',
      },
      {
        'Data Source': 'ITC Trade Map',
      },
    ],
    Bangladesh: [
      {
        link: 'Export Statistics',
      },
      {
        'Data Source': 'Export Promotion Bureau of Bangladesh',
      },
    ],
    Bhutan: [
      {
        link: 'Annual Trade Statistics 2021',
      },

      {
        'Data Source': 'Ministry of Finance, Royal Government of Bhutan',
      },
    ],
    India: [
      {
        link: 'Export Country-wise',
      },
      {
        link: 'Export Import Data Bank expert region wise',
      },
      {
        link: 'Export Import Data Bank',
      },
      {
        link: 'Import Country-wise',
      },
      {
        link: 'Import Country-wise',
      },
      {
        link: 'Region wise exports South Asia',
      },
      {
        link: 'Region wise imports South Asia',
      },
      {
        link: 'Region wise',
      },
      {
        link: 'Total Trade Top countries',
      },
      {
        'Data Source':
          'Ministry of Commerce and Industry (Department of Commerce), Government of India',
      },
    ],
    Maldives: [
      {
        link: 'Total Export 2020',
        type: '.xlsx',
      },
      {
        link: 'Total Import 2020',
        type: '.xlsx',
      },

      {
        'Data Source': 'Maldives Custom Services',
      },
    ],

    Nepal: [
      {
        link: 'Export  by Countries',
        type: '.xlsx',
      },
      {
        link: 'Exports Commodities',
        type: '.xlsx',
      },
      {
        link: 'Import by Countries',
        type: '.xlsx',
      },
      {
        link: 'Imports by Commodities',
        type: '.xlsx',
      },

      { 'Data Source': 'Nepal Trade Portal' },
    ],
    Pakistan: [
      {
        link: 'Export Receipts by All Commodities',
        type: '.xlsx',
      },
      {
        link: 'Export Receipts by all Countries',
        type: '.xlsx',
      },
      {
        link: 'Export Receipts by Commodities and Groups',
        type: '.xlsx',
      },
      {
        link: 'Export Receipts by Selected Countries or Regions',
        type: '.xlsx',
      },
      {
        link: 'Import Payments by all Commodities',
        type: '.xlsx',
      },
      {
        link: 'Import Payments by All Countries',
        type: '.xlsx',
      },
      {
        link: 'Import Payments by Commodities and Groups',
        type: '.xlsx',
      },
      {
        link: 'Import Payments by Selected Countries & Regions',
        type: '.xlsx',
      },
      { 'Data Source': 'State Bank of Pakistan' },
    ],
    'Sri Lanka': [
      {
        link: 'International Trade Statistics 2021',
      },
      { 'Data Source': 'Central Bank of Sri Lanka' },
    ],
    'Socioeconomic Data': [
      {
        link: 'South Asia Socio-Economic Data 2021',
      },
      { 'Data Source': 'World Bank' },
    ],
  };
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  return (
    <>
      <SEO
        title="Economic Statistics"
        desciption="Know more about economic statistics of South Asian Countries"
      />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area mt--30 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Tabs defaultIndex={query.get('t') ? query.get('t') : 0}>
                    <div className="row row--30 align-items-center">
                      <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="rn-default-tab">
                          <div className="tab-button-panel">
                            <TabList className="tab-button react-tabs__tab">
                              {pdf.map((item, index) => {
                                return (
                                  <Tab key={index} style={{ padding: '12px 20px !important' }}>
                                    <div className="rn-tab-button">
                                      <button>{item}</button>
                                    </div>
                                  </Tab>
                                );
                              })}
                            </TabList>
                          </div>

                          <div className="tab-content-panel">
                            {pdf.map((name, index) => {
                              return (
                                <TabPanel key={index}>
                                  <div className="rn-tab-content">
                                    <div className="inner">
                                      <div className="col-lg-12">
                                        <div className="rn-contact-address mt_dec--30">
                                          <Table striped bordered hover>
                                            <tbody>
                                              {Object.keys(documents).map((item) => {
                                                if (item !== name) return;
                                                return documents[item].map((elem) => {
                                                  return (
                                                    <>
                                                      {elem.link ? (
                                                        <tr>
                                                          <td>
                                                            <a
                                                              href={
                                                                '../../../documents/Economic Statistics/' +
                                                                name +
                                                                '/' +
                                                                elem.link +
                                                                (elem.type || '.pdf')
                                                              }
                                                              target="_blank"
                                                              className="color-black"
                                                            >
                                                              {elem.link}
                                                            </a>
                                                          </td>
                                                          <td>
                                                            <a
                                                              href={
                                                                '../../../documents/Economic Statistics/' +
                                                                name +
                                                                '/' +
                                                                elem.link +
                                                                (elem.type || '.pdf')
                                                              }
                                                              target="_blank"
                                                            >
                                                              {elem.type ? (
                                                                <BsFileEarmarkExcelFill
                                                                  color="green"
                                                                  size={20}
                                                                />
                                                              ) : (
                                                                <GoFilePdf color="red" size={20} />
                                                              )}
                                                            </a>
                                                          </td>
                                                        </tr>
                                                      ) : (
                                                        <tr>
                                                          <td>
                                                            <b>
                                                              {'Data Source: ' +
                                                                elem['Data Source']}
                                                            </b>
                                                          </td>
                                                          <td></td>
                                                        </tr>
                                                      )}
                                                    </>
                                                  );
                                                });
                                              })}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default EconomicStats;
