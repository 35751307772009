import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Document, Page, pdfjs } from 'react-pdf';
import ViewSDKClient from '../../ViewSDKClient';
import PdfReader from './PdfReader';
const Destinations = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const pdf = [
    'Afghanistan',
    'Bangladesh',
    'Bhutan',
    'India',
    'Maldives',
    'Nepal',
    'Pakistan',
    'Sri Lanka',
  ];
  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <>
      <SEO title="Publications | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area mt--30 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Tabs transition={true} defaultIndex="none">
                    <div className="row row--30 align-items-center">
                      <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="rn-default-tab">
                          <div className="tab-button-panel">
                            <TabList className="tab-button react-tabs__tab">
                              {pdf.map((item, index) => {
                                return (
                                  <Tab key={index} tabIndex={index}>
                                    <div className="rn-tab-button">
                                      <button>{item}</button>
                                    </div>
                                  </Tab>
                                );
                              })}
                            </TabList>
                          </div>

                          <div className="tab-content-panel">
                            {pdf.map((item, index) => {
                              return (
                                <TabPanel key={index}>
                                  <div className="rn-tab-content">
                                    <div className="inner">
                                      <div className="col-lg-12">
                                        <div className="rn-contact-address mt_dec--30">
                                          {/*<Document*/}
                                          {/*  onLoadSuccess={onDocumentLoadSuccess}*/}
                                          {/*  file={`../../../documents/Destinations/${item}.pdf`}*/}
                                          {/*  options={{*/}
                                          {/*    workerSrc: `../../../documents/Destinations/${item}.pdf`,*/}
                                          {/*  }}*/}
                                          {/*>*/}
                                          {/*  {Array.from(new Array(numPages), (el, index) => (*/}
                                          {/*    <Page*/}
                                          {/*      scale={3}*/}
                                          {/*      key={`page_${index + 1}`}*/}
                                          {/*      pageNumber={index + 1}*/}
                                          {/*    />*/}
                                          {/*  ))}*/}
                                          {/*</Document>*/}

                                          <PdfReader
                                            className="pdfReader"
                                            url={
                                              process.env.REACT_APP_SAARC_DEFAULT_URL +
                                              '/documents/Destinations/' +
                                              item +
                                              '.pdf'
                                            }
                                          />
                                          <a
                                            className="mt-5 color-black"
                                            href={
                                              process.env.REACT_APP_SAARC_DEFAULT_URL +
                                              '/documents/Destinations/' +
                                              item +
                                              '.pdf'
                                            }
                                            target="_blank"
                                          >
                                            Click to view in browser
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Destinations;
