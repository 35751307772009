import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { get } from '../../utils/api_helper';
import SectionTitle from '../sectionTitle/SectionTitle';
import Slider from 'react-slick';
const SaarcNews = () => {
  const [news, setNews] = useState([]);
  const [newsIndex, setNewsIndex] = useState(0);

  useEffect(() => {
    async function getNews() {
      await get('/news')
        .then((resp) => {
          setNews(resp);
        })
        .catch((err) => {
          console.error('error', err);
        });
    }
    getNews();
  }, []);
  const settings = {
    dots: false,
    vertical: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    useTransform: false,
    height: '0px',
    beforeChange: (current, next) => {
      setNewsIndex(current);
    },
  };
  return (
    <div
      className=" margin-laptop-for-events  col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12  "
      id="news"
    >
      <div className="content">
        <div className="pb--50 inner">
          <div>
            <SectionTitle textAlign="" radiusRounded="" title="SAARC News" titleLine="title-line" />
            <Tabs>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-xs-12 col-md-12 col-xl-7 col-lg-7">
                  <div className="advance-tab-content advance-tab-content-1">
                    {news &&
                      news.slice(newsIndex).map((item, index) => (
                        <TabPanel key={index}>
                          <div className="tab-content mb-5">
                            <div className="inner">
                              <a href={item.url} target="_blank">
                                <div
                                  className="top-0 position-relative featured-news-image news-text"
                                  style={{
                                    backgroundImage: `url(${item.imageUrl})`,
                                  }}
                                >
                                  <div className="pb-2 headline-opacity headline-position">
                                    <h4
                                      title={item.title}
                                      className="pt-1 pl-3 pr-4 no-margin cursor-pointer "
                                    >
                                      {item.title}
                                    </h4>
                                    <a href={item.url} className="pl-3" target="_blank">
                                      {item.tagline ? item.tagline : ''}
                                    </a>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </TabPanel>
                      ))}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 mt_md--30 mt_sm--30 d-xs-none d-sm-none d-md-none d-lg-block d-xl-block">
                  <div className="row  advance-tab-button-1 right-align">
                    <TabList className=" tab-button-list">
                      <Slider {...settings}>
                        {news &&
                          news.map((item, index) => {
                            return (
                              <Tab key={index}>
                                <a href={item.url} target="_blank">
                                  <div className=" news-text tab-button row no-gutters">
                                    <div className="col-lg-5 col-sm-2 mr-3">
                                      <img
                                        className="news-thumb"
                                        src={item.imageUrl}
                                        alt={item.title}
                                      />
                                    </div>
                                    <div className="col-lg-6 col-sm">
                                      <h5 title={item.title} className=" cursor-pointer no-margin">
                                        {item.title.length > 50
                                          ? item.title.substring(0, 50) + '...'
                                          : item.title}
                                      </h5>
                                      <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener"
                                        className=""
                                      >
                                        {item.tagline ? item.tagline : ''}
                                      </a>
                                    </div>
                                  </div>
                                </a>
                              </Tab>
                            );
                          })}
                      </Slider>
                    </TabList>
                  </div>
                </div>
              </div>
            </Tabs>
            {/*<a href="#">*/}
            {/*  /!*<h6>View All News</h6>*!/*/}
            {/*</a>*/}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SaarcNews;
