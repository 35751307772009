import React, { useEffect, useState } from 'react';
import { GoVerified } from 'react-icons/go';
import { get } from '../../utils/api_helper';
import { COMPANY_STATUS, getUserId } from '../../utils';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CallToActionCompany = (props) => {
  const [cover, setCover] = useState('');
  const [logo, setLogo] = useState('');
  let userId = getUserId();

  useEffect(() => {
    async function getCoverImage() {
      await get('/uploads/' + props.coverImage)
        .then((resp) => {
          if (resp) {
            setCover(process.env.REACT_APP_SAARC_API + resp.path.substring(11).replace(/\\/g, '/'));
          }
        })
        .catch((err) => {
          setCover('/images/company/default-cover.jpg');
          console.error('error on fetching cover image', err);
        });
    }
    async function getLogo() {
      let logo = props.logo.split(';')[0];
      await get('/uploads/' + props.logo)
        .then((resp) => {
          if (resp) {
            setLogo(process.env.REACT_APP_SAARC_API + resp.path.substring(11).replace(/\\/g, '/'));
          }
        })
        .catch((err) => {
          setLogo('/images/company/default-banner.png');
          console.error('error on fetching logo', err);
        });
    }
    if (props.logo) {
      getLogo();
    }
    getCoverImage();
  }, [props.coverImage]);
  const scrollToId = (e, name) => {
    e.preventDefault();
    if (name === 'product') {
      if (document.getElementById('product')) {
        document.getElementById('product').scrollIntoView();
      }
    } else if (name === 'profile') {
      if (document.getElementById('profile')) {
        document.getElementById('profile').scrollIntoView();
      }
    } else if (name === 'gallery') {
      if (document.getElementById('gallery')) {
        document.getElementById('gallery').scrollIntoView();
      }
    }
  };

  return (
    <>
      {/*company page cover photo*/}
      <div className="container">
        <div
          className=" clltoaction-style-default style-3 style-company bg_image"
          style={{
            backgroundImage: `url('${cover}')`,
          }}
        />

        {/*company details after cover photo*/}
        <div style={{ 'background-color': 'white' }}>
          <div className="banner-content ">
            <div className="row">
              <div
                className="col-xl-2 col-lg-6 col-md-6 col-sm-12 col-xs-12 "
                style={{ 'text-align': 'center' }}
              >
                <a
                  className="profile-avatar"
                  style={{
                    backgroundImage: `url('${logo}')`,
                  }}
                />
              </div>
              {/*verified badge start*/}
              <div className={'col-xl-6 col-md-6 col-xs-12 company-name-spacing '}>
                <div style={{ display: 'block' }}>
                  <h1
                    className="company-name verified-listing"
                    style={{ 'margin-bottom': '0 !important' }}
                  >
                    {props.companyHeader.name}
                    {props.companyHeader &&
                    props.companyHeader.Chamber &&
                    props.companyHeader.Chamber.status === COMPANY_STATUS.APPROVED ? (
                      <GoVerified color="blue" className="verified-listing" />
                    ) : null}
                    {/*verified badge end*/}
                    {console.log('props',props)}
                  </h1>
                </div>
                <div style={{ display: 'block' }}>
                  <h2 className="company-tagline">{props.companyHeader.tagline}</h2>
                </div>
              </div>

              {props && props.companyHeader && props.companyHeader.UserId === userId ? (
                <div className={'col-xl-2 col-md-6 col-xs-12 text-center mt-5'}>
                  <a
                    className="ml-xl-3 btn btn-lg btn-danger color-white"
                    href={'users/company-registration'}
                  >
                    Edit Company
                  </a>
                </div>
             ) : null} 
            </div>
          </div>
          <div className="col-12 custom-border-2 ">
            <div className="row profile-header d-lg-block d-xl-block d-md-none d-sm-none d-xs-none ">
              <div className="offset-3 profile-container">
                <div className="profile-menu">
                  <div
                    className="col-3 hover-underline-animation"
                    onClick={(e) => scrollToId(e, 'profile')}
                  >
                    Profile
                  </div>
                  <div
                    className="col-3 hover-underline-animation"

                    onClick={(e) => scrollToId(e, 'product')}
                  >
                    Products
                  </div>
                  <div
                    className="col-3 hover-underline-animation"
                    onClick={(e) => scrollToId(e, 'gallery')}
                  >
                    Gallery
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CallToActionCompany;
