import GoogleMapReact from 'google-map-react';
import { ImLocation } from 'react-icons/all';

const AnyReactComponent = ({ text }) => (
  <>
    <ImLocation color="red" />
  </>
);
const GoogleMapStyle = (props) => {
  let coordinateData = props.coordinates.split(';');
  const defaultProps = {
    center: {
      lat: parseInt(coordinateData[0]),
      lng: parseInt(coordinateData[1]),
    },
    zoom: 15,
  };

  return (
    // Important! Always set the container height explicitly
    <div className="google-map-style-1">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={parseInt(coordinateData[0])}
          lng={parseInt(coordinateData[1])}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMapStyle;
