import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Table } from 'react-bootstrap';
import { GoFilePdf } from 'react-icons/all';
import { useLocation } from 'react-router-dom';
const TradeGuides = () => {
  const pdf = [
    'Afghanistan',
    'Bangladesh',
    'Bhutan',
    'India',
    'Maldives',
    'Nepal',
    'Pakistan',
    'Sri Lanka',
  ];
  const documents = {
    Afghanistan: [
      {
        link: 'AFGHANISTAN National Trade Poilcy 2019-2023',
        title: 'AFGHANISTAN National Trade Policy 2019-2023',
      },
      {
        link: 'Afghansitan Customs law',
        title: 'Afghanistan Customs law',
      },
      {
        link: 'Investors Guide to Afghanistan',
        title: 'Investors Guide to Afghanistan',
      },
      {
        link: 'Tariffs and imports Summary and duty ranges',
        title: 'Tariffs and imports Summary and duty ranges',
      },
      {
        link: 'Trade and Tariff Policy Framework for Afghanistan.pdf',
        title: 'Trade and Tariff Policy Framework for Afghanistan.pdf',
      },
    ],
    Bangladesh: [
      {
        link: 'BANGLADESH Export  FIDI Customs Guide',
        title: 'BANGLADESH Export  FIDI Customs Guide',
      },
      {
        link: 'Custom Duties-Tariff Details',
        title: 'Custom Duties-Tariff Details',
      },
      {
        link: 'Customs Tariff 2020-21',
        title: 'Customs Tariff 2020-21',
      },
      {
        link: 'Export Policy 2018-21-1 (1)EPB Report 25.05.2020',
        title: 'Export Policy 2018-21-1 (1)EPB Report 25.05.2020',
      },
      {
        link: 'Import Policy_6(10.2.2016)',
        title: 'Import Policy_6(10.2.2016)',
      },
      {
        link: 'Import Requirements and Documentation',
        title: 'Import Requirements and Documentation',
      },
      {
        link: 'Prohibited & Restricted Imports',
        title: 'Prohibited & Restricted Imports',
      },
      {
        link: 'Standards for Trade',
        title: 'Standards for Trade',
      },
      {
        link: 'Trade License Application Form',
        title: 'Trade License Application Form',
      },
    ],
    Bhutan: [
      {
        link: 'Bhutan Classification & Tariff Schedule 2017',
        title: 'Bhutan Classification & Tariff Schedule 2017',
      },
      { link: 'BHUTAN DOMESTIC-EXTERNAL-TRADE', title: 'BHUTAN DOMESTIC-EXTERNAL-TRADE' },
      { link: 'CustomDuty Act of Bhutan 2021', title: 'CustomDuty Act of Bhutan 2021' },
      {
        link: 'Guideline for issuance of Certificate of Origin',
        title: 'Guideline for issuance of Certificate of Origin',
      },
      { link: 'Import verification form', title: 'Import verification form' },
      { link: 'Import-License-Form', title: 'Import-License-Form' },
      { link: 'Made-in-Bhutan-Guideline', title: 'Made-in-Bhutan-Guideline' },
      { link: 'Registered Exporter System', title: 'Registered Exporter System' },
      { link: 'Rule_SaleTaxCustomsExcise', title: 'Rule_SaleTaxCustomsExcise' },
      { link: 'RULES AND PROCEDURES', title: 'RULES AND PROCEDURES' },
      { link: 'Trade Fair Guidelines', title: 'Trade Fair Guidelines' },
      { link: 'Trade guide Annual-Magazine', title: 'Trade guide Annual-Magazine' },
    ],
    India: [
      {
        link: '/Brief on Seventh (7th) Trade Policy Review of India by the WTO, January 2021',
        title: 'Brief on Seventh (7th) Trade Policy Review of India by the WTO, January 2021',
      },
      {
        link: '/Custom Tarriff India 2022',
        title: 'Custom Tarriff India 2022',
      },
      {
        link: '/Customs Tariff Rules ',
        title: 'Customs Tariff Rules ',
      },
      {
        link: '/Customs Manual 2018',
        title: 'Customs Manual 2018',
      },
      {
        link: '/exports guide',
        title: 'exports guide',
      },
      {
        link: '/GENERAL EXEMPTIONS - IMPORT TARIFF',
        title: 'GENERAL EXEMPTIONS - IMPORT TARIFF',
      },
      {
        link: '/procedure_for_export_of_green_chillies_10august2021',
        title: 'procedure_for_export_of_green_chillies_10august2021',
      },
      {
        link: '/procedureforexportofgrapes_15Dec_2021-22',
        title: 'Procedure For Export Of Grapes 15 Dec 2021-22',
      },
      {
        link: '/procedureforexportofpomegranatesept2017',
        title: 'Procedure For Export Of Pomegranate Sept-2017',
      },
      {
        link: '/PROCEDURES FOR EXPORT OF PEANUTS AND PEANUT PRODUCTS',
        title: 'PROCEDURES FOR EXPORT OF PEANUTS AND PEANUT PRODUCTS',
      },
      {
        link: '/THE FOREIGN TRADE (DEVELOPMENT AND REGULATION) ACT, 1992 No.22',
        title: 'THE FOREIGN TRADE (DEVELOPMENT AND REGULATION) ACT, 1992 No.22',
      },
    ],
    Maldives: [
      {
        link: 'Custom Tarrifs Maldives',
        title: 'Custom Tariffs Maldives',
      },
      {
        link: 'export-import-16th-amendment-act-16-2018 (1)',
        title: 'Export Import 16th Amendment Act-16-2018',
      },
      {
        link: 'export-import-16th-amendment-act-16-2018',
        title: 'Export Import 16th Amendment Act-16-2018',
      },
      {
        link: 'export-import-18th-amendment-act-29-2020 (1)',
        title: 'export-import-18th-amendment-act-29-2020',
      },
      {
        link: 'export-import-18th-amendment-act-29-2020',
        title: 'export-import-18th-amendment-act-29-2020',
      },
      {
        link: 'presidential-decree-on-import-prohibited-single-use-plastics-2020-08',
        title: 'presidential-decree-on-import-prohibited-single-use-plastics-2020-08',
      },
      {
        link: 'presidential-decree-on-import-prohibited-single-use-plastics-2021-03',
        title: 'presidential-decree-on-import-prohibited-single-use-plastics-2021-03',
      },
      {
        link: 'regulation-on-export-import-re-export-1st-amendment-2015-r-116',
        title: 'regulation-on-export-import-re-export-1st-amendment-2015-r-116',
      },
      {
        link: 'regulation-on-export-import-re-export-2nd-amendment-2016-r-4',
        title: 'regulation-on-export-import-re-export-2nd-amendment-2016-r-4',
      },
      {
        link: 'regulation-on-export-import-re-export-3rd-amendment-2019-r-1012',
        title: 'regulation-on-export-import-re-export-3rd-amendment-2019-r-1012',
      },
      {
        link: 'regulation-on-export-import-re-export-4th-amendment-2021-r-32',
        title: 'regulation-on-export-import-re-export-4th-amendment-2021-r-32',
      },
      {
        link: 'regulation-on-export-import-re-export-2012-r-34',
        title: 'regulation-on-export-import-re-export-2012-r-34',
      },
      {
        link: 'regulation-on-halal-certification-2013-r-1651',
        title: 'regulation-on-halal-certification-2013-r-1651',
      },
    ],

    Nepal: [
      {
        link: 'Amendment on the procedure to provide grants for export 2075',
        title: 'Amendment on the procedure to provide grants for export 2075',
      },
      {
        link: 'Certitificate of Origin',
        title: 'Certificate of Origin',
      },
      {
        link: 'Copyright Rules, 2061 (2004)',
        title: 'Copyright Rules, 2061 (2004)',
      },
      {
        link: 'Criteria to be adopted by industry, commerce and supply sector during the COVID-19 .',
        title: 'Criteria to be adopted by industry, commerce and supply sector during the COVID-19',
      },
      {
        link: 'Customs Tariff  2077 (2020-21) Eng',
        title: 'Customs Tariff  2077 (2020-21) Eng',
      },
      {
        link: 'Customs Tariff 2017_18',
        title: 'Customs Tariff 2017_18',
      },
      {
        link: 'Export and Import rules,2034',
        title: 'Export and Import rules,2034',
      },
      {
        link: 'Exports Rules and Regulations',
        title: 'Exports Rules and Regulations',
      },
      {
        link: 'Guide to export',
        title: 'Guide to export',
      },
      {
        link: 'Guide to Import',
        title: 'Guide to Import',
      },
      {
        link: 'Imports Rules and Regulations',
        title: 'Imports Rules and Regulations',
      },
      {
        link: 'Mining and Minerals Regulations, 2056',
        title: 'Mining and Minerals Regulations, 2056',
      },
      {
        link: 'Monitoring indicators of safety measures to be adopted by Ministry of industry, commerce and supply sector during Covid-19 epidemic (checklist).',
        title:
          'Monitoring indicators of safety measures to be adopted by Ministry of industry, commerce and supply sector during Covid-19 epidemic (checklist).',
      },
      {
        link: 'Nepal Quality (Certificate) Rules, 2040',
        title: 'Nepal Quality (Certificate) Rules, 2040',
      },
      {
        link: 'Products from Nepal',
        title: 'Products from Nepal',
      },
      {
        link: 'Safeguards, Anti-Dumping and Countervailing Regulations, 2077',
        title: 'Safeguards, Anti-Dumping and Countervailing Regulations, 2077',
      },
      {
        link: 'Standards related to quarrying, sale and management of stone, ballast and sand, 2077',
        title:
          'Standards related to quarrying, sale and management of stone, ballast and sand, 2077',
      },
      {
        link: 'the-financial-procedures-rules_1470736726',
        title: 'The Financial Procedures Rules 1470736726',
      },
    ],
    Pakistan: [
      {
        link: 'Export Policy order-2022-SRO-544-2022-dt.22.4.22',
        title: 'Export Policy order-2022-SRO-544-2022-dt.22.4.22',
      },
      {
        link: 'Import Policy Order-2022-SRO-No.-545I-2022-dt.-22.4-2022',
        title: 'Import Policy Order-2022-SRO-No.-545I-2022-dt.-22.4-2022',
      },
      {
        link: 'National-Tariff-Policy-2019-24',
        title: 'National-Tariff-Policy-2019-24',
      },
      {
        link: 'Pakistan Custom FBR ',
        title: 'Pakistan Custom FBR ',
      },
      {
        link: 'PakistanCustomsTariff',
        title: 'PakistanCustomsTariff',
      },
      {
        link: 'Strategic trade policy framework Final-STPF-2020-25-1',
        title: 'Strategic trade policy framework Final-STPF-2020-25-1',
      },
      {
        link: 'Textiles-and-Apparel-Policy-2020-25',
        title: 'Textiles-and-Apparel-Policy-2020-25',
      },
      {
        link: 'Trade-Related-Investment-Policy-Framework-2015-23',
        title: 'Trade-Related-Investment-Policy-Framework-2015-23',
      },
    ],
    'Sri Lanka': [
      {
        link: 'A comprehensive guide for trade',
        title: 'A comprehensive guide for trade',
      },
      {
        link: 'Documents Mandatory to Operate as New Customs House Agent ',
        title: 'Documents Mandatory to Operate as New Customs House Agent ',
      },
      {
        link: 'export-procedure-21-10-2014',
        title: 'export-procedure-21-10-2014',
      },
      {
        link: 'general-guidance-to-export',
        title: 'general-guidance-to-export',
      },
      {
        link: 'Importing Goods',
        title: 'Importing Goods',
      },
      {
        link: 'REGISTER AS AN EXPORTER AT EDB',
        title: 'REGISTER AS AN EXPORTER AT EDB',
      },
      {
        link: 'Sri lanka Customs Import Tariff',
        title: 'Sri lanka Customs Import Tariff',
      },
      {
        link: 'Sri Lanka Customs National Imports Tariff Guide',
        title: 'Sri Lanka Customs National Imports Tariff Guide',
      },
    ],
  };
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  return (
    <>
      <SEO
        title="Publications"
        description="Know more about trading guidelines for SAARC countries"
      />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area mt--30 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Tabs defaultIndex={query.get('t') ? query.get('t') : 0}>
                    <div className="row row--30 align-items-center">
                      <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="rn-default-tab">
                          <div className="tab-button-panel">
                            <TabList className="tab-button react-tabs__tab">
                              {pdf.map((item, index) => {
                                return (
                                  <Tab key={index}>
                                    <div className="rn-tab-button">
                                      <button>{item}</button>
                                    </div>
                                  </Tab>
                                );
                              })}
                            </TabList>
                          </div>

                          <div className="tab-content-panel">
                            {pdf.map((name, index) => {
                              return (
                                <TabPanel key={index}>
                                  <div className="rn-tab-content">
                                    <div className="inner">
                                      <div className="col-lg-12">
                                        <div className="rn-contact-address mt_dec--30">
                                          <Table striped bordered hover>
                                            <tbody>
                                              {Object.keys(documents).map((item) => {
                                                if (item !== name) return;
                                                return documents[item].map((elem) => {
                                                  return (
                                                    <>
                                                      <tr>
                                                        <td>
                                                          <a
                                                            rel="noreferrer"
                                                            href={
                                                              '../../../documents/Trade Guides/' +
                                                              name +
                                                              '/' +
                                                              elem.link +
                                                              '.pdf'
                                                            }
                                                            target="_blank"
                                                            className="color-black"
                                                          >
                                                            {elem.title}
                                                          </a>
                                                        </td>
                                                        <td>
                                                          <a
                                                            href={
                                                              '../../../documents/Trade Guides/' +
                                                              name +
                                                              '/' +
                                                              elem.link +
                                                              '.pdf'
                                                            }
                                                            target="_blank"
                                                          >
                                                            <GoFilePdf color="red" size={20} />
                                                          </a>
                                                        </td>
                                                      </tr>
                                                    </>
                                                  );
                                                });
                                              })}
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </TabPanel>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default TradeGuides;
