import PropTypes from 'prop-types';
import React from 'react';

const SEO = ({title, description}) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="robots" content="all" />
      <meta
        name="keywords"
        content="SAARC, Bazaar, SAARC Bazaar, saarc bazaar, saarcbazaar, south asia, south, asia, asian, asian countries, suppliers, B2B, marketplace"
      />
      <meta
        name="description"
        content={ description || 'SAARC Bazaar | Be Part of the Legacy of a Rising South Asia.'}
      />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
