import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import { get } from '../../utils/api_helper';
import { TabPanel, Tabs } from 'react-tabs';

const ViewPublications = () => {
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: 'Title',
        field: 'title',
        sort: 'asc',
        width: '150',
      },
    ],
    rows: [],
  });
  useEffect(() => {
    async function getPublications() {
      await get('/publications')
        .then((resp) => {
          setDatatable((prev) => ({ ...prev, rows: resp }));
        })
        .catch((err) => {
          console.error('error', err);
        });
    }
    getPublications();
  }, []);

  return (
    <>
      <SEO title="Publications | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area mt--30 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3 className="text-center border-bottom mb-5">Publications</h3>

                  <Tabs>
                    <div className="row row--30 align-items-center">
                      <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="tab-button-panel">
                          <div className="tab-content-panel">
                            <TabPanel>
                              <div className="rn-tab-content">
                                <div className="inner">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="rn-contact-address mt_dec--30">
                                        <div className="row">
                                          {datatable.rows.map((item, index) => (
                                            <div
                                              className="col-lg-3 col-md-6 col-sm-12"
                                              key={index}
                                            >
                                              <div className="pub-items">
                                                <div className="mb-3 thumbnail publication-thumb">
                                                  <img
                                                    src={
                                                      item.tagline ||
                                                      'https://dummyimage.com/400x400/c0c0c0/000'
                                                    }
                                                    alt="Publication Image"
                                                  />
                                                </div>
                                                <div className="inner">
                                                  <a href={item.url}>
                                                    <h4 className="title text-ellipsis">
                                                      {item.title}
                                                    </h4>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default ViewPublications;
