import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import EventsProp from './itemProp/EventsProp';

const EventsList = () => {
  return (
    <>
      <SEO title="Events and Exhibitions | SAARC Bazaar" />
      <Layout>
        {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog List View"
                /> */}

        <div className="main-content">
          {/* Start Events Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--30">
                <EventsProp
                  column="col-lg-6 mt--30"
                  StyleVarProp="box-card-style-default card-list-view"
                />
              </div>
            </div>
          </div>
          {/* End Events Area  */}
        </div>
      </Layout>
    </>
  );
};

export default EventsList;
