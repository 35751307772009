import React from 'react';
import { Link } from 'react-router-dom';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';

const Social = (props) => {
  return (
    <>
      <div className="col-12 social-icon social-default icon-naked mt--15 company_desc">
        {props.socialData && props.socialData.facebook ? (
          <div
            className={
              props.socialData.twitter || props.socialData.instagram
                ? 'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 facebook mt-2'
                : 'col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 facebook mt-2'
            }
          >
            <Link to={{ pathname: props.socialData.facebook }} target="_blank">
              <BsFacebook size={25} /> Facebook
            </Link>
          </div>
        ) : null}
        {props.socialData && props.socialData.twitter ? (
          <div
            className={
              props.socialData.facebook || props.socialData.instagram
                ? 'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 twitter mt-2'
                : 'col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 twitter mt-2 '
            }
          >
            <Link to={{ pathname: props.socialData.twitter }} target="_blank">
              <BsTwitter size={25} /> Twitter
            </Link>
          </div>
        ) : null}
        {props.socialData && props.socialData.instagram ? (
          <div
            className={
              props.socialData.facebook || props.socialData.twitter
                ? 'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 insta mt-2'
                : 'col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 insta mt-2'
            }
          >
            <Link to={{ pathname: props.socialData.instagram }} target="_blank">
              <BsInstagram size={25} /> Instagram
            </Link>
          </div>
        ) : null}
      </div>

      {/* End Elements Area  */}
    </>
  );
};
export default Social;
