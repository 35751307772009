import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { get } from '../../../utils/api_helper';
import { MdVerified } from 'react-icons/md';
import { COMPANY_STATUS } from '../../../utils';

const RecommendedProductsTwo = ({ data, StyleVar }) => {
  const [supplier, setSupplier] = useState([]);
  const [attachments, setAttachments] = useState('');

  useEffect(() => {
    async function getSupplierData() {
      await get('/companies/' + data.CompanyId)
        .then((resp) => {
          setSupplier(resp);
        })
        .catch((err) => {
          console.error('Error getting Recommended products', err);
        });
    }
    getSupplierData();
  }, []);

  useEffect(() => {
    if (data && data.attachment) {
      async function getAttachment() {
        await get('/uploads/' + data.attachment.split(';')[0])
          .then((resp) => {
            setAttachments(resp.path.split(';')[0].substring(11).replace(/\\/g, '/'));
          })
          .catch((err) => {
            console.error('Error getting Attachments', err);
          });
      }
      getAttachment();
    }
  }, []);

  return (
    <>
      <div className="row ">
        <div className="col-2 "></div>
        <div className={` ${StyleVar} col-9 borderBottom` }>
            <div className="inner mt-5  ">
            <div className="thumbnail ">
              <Link to={process.env.PUBLIC_URL + `/product-details/${data.id}`}>
                {data && data.attachment && attachments ? (
                  <>
                    <figure className={data.featured ? 'img tag tag-featured' : ''}>
                      <img
                        className="img-fluid d-flex justify-content-center "
                        style={{
                          // height: '200px',
                          overflow: 'hidden',

                        }}
                        src={process.env.REACT_APP_SAARC_IMAGE_API + attachments}
                        alt="Product"
                      />
                    </figure>
                  </>
                ) : (
                  <img
                    src={process.env.REACT_APP_SAARC_IMAGE_API + '/images/no-preview.png'}
                    alt="not found"
                    style={{
                      width: '100px',
                      height: '100px',
                      overflow: 'hidden',
                    }}
                  />
                )}
              </Link>
            </div>
            <div className="content" style={{ justifyContent: 'center' }}>
              <ul className="main-page-products">
                <li className="text-ellipsis text-capitalize">
                  <Link
                    className=" subtitle d-flex justify-content-center"
                    to={process.env.PUBLIC_URL + `/product-details/${data.id}`}
                  >
                    {data?.price === null ? (
                        <p>Ask for price</p>
                    ) : (
                        <h6 className="">
                          {data?.currency + '.  ' + data?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </h6>
                    )}

                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
};
RecommendedProductsTwo.propTypes = {
  data: PropTypes.object,
};
export default RecommendedProductsTwo;
