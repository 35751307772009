import React, { useState, useEffect } from 'react';
import BlogClassicData from '../../../data/blog/BlogList.json';
import { FaSpinner } from 'react-icons/fa';
import filters from './Filters';
import EventsListTwo from './EventsListTwo';
import { get } from '../../../utils/api_helper';
import moment from 'moment';

const alldataBLog = BlogClassicData;
const EventsProp = ({ column, StyleVarProp }) => {
  const [getAllItems] = useState(alldataBLog);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [noMorePost, setNoMorePost] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, []);

  const handleLoadmorebl = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter((data) => data.text === activeFilter && data.id <= tempCount)
        );
      }
    }
  };
  const [dataApi, setDataApi] = useState([]);

  useEffect(() => {
    async function getEvents() {
      await get('/events')
        .then((resp) => {
          let today = new Date().toISOString().slice(0, 10);
          setDataApi(resp.filter((event) => moment(today).isSameOrBefore(event.end)));
        })
        .catch((err) => {
          console.log('error getting events', err);
        });
    }
    getEvents();
  }, []);

  return (
    <>
      <div className="col-lg-12">
        <div className="row row--15">
          {dataApi.map((item) => (
            <div key={item.id} className={column}>
              <EventsListTwo StyleVar={StyleVarProp} data={item} />
            </div>
          ))}
        </div>
      </div>
      <div className="col-lg-12">
        <div className="rwt-load-more text-center mt--60">
          <button
            className="btn btn-default btn-icon"
            onClick={handleLoadmorebl}
            disabled={noMorePost ? 'disabled' : null}
          >
            {noMorePost ? (
              'No Post Here'
            ) : (
              <span>
                View More Post
                <span className="icon">
                  <FaSpinner />
                </span>
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};
export default EventsProp;
