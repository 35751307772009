import ProductsData from './productsData';

const DisplayProducts = (props) => {
  return (
    <>
      <div className="main-content">
        <div className="rwt-portfolio-area">
          <div className="">
            <div className="row no-gutters">
              <div className="col-lg-12 mb-5 mt_dec--20" style={{ 'z-index': '1' }}>
                <ProductsData
                  Column={`${props.col ? props.col : ' col-lg-4 '}` + ' col-md-6 mt--30 portfolio'}
                  companyId={props.companyId}
                  filtered={props.products}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DisplayProducts;
