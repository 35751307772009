import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { get } from '../../utils/api_helper';
import Slider from 'react-slick';
import moment from 'moment';
import SectionTitle from '../sectionTitle/SectionTitle';

const SaarcEvents = (props) => {
  const [events, setEvents] = useState([]);
  const settings = {
    dots: false,
    vertical: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
    useTransform: false,
    height: '0px',
    infinite: false,
  };
  useEffect(() => {
    async function getEvents() {
      await get('/events')
        .then((resp) => {
          let today = new Date().toISOString().slice(0, 10);
          setEvents(resp.filter((event) => moment(today).isSameOrBefore(event.end)));
        })
        .catch((err) => {
          console.error('error getting events', err);
        });
    }
    getEvents();
  }, []);

  return (
    <div
      className="margin-laptop-for-events col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 "
      id="events"
    >
      <div className="event-container mt_dec--events ">
        <div className="content">
          <div className="inner">
            <div>
              <SectionTitle
                textAlign=""
                radiusRounded=""
                title="Events & Exhibitions"
                titleLine="title-line"
              />

              <Slider {...settings}>
                {events &&
                  events.map((item, index) => {
                    const day = moment(item.start, 'YYYY-MM-DD').format('DD-MMM-YYYY').split('-');
                    return (
                      <div key={index}>
                        <a href={item.link} target="_blank">
                          <div className=" event-date float-left text-center mr-3">
                            <h3 className="no-margin">{day[0]}</h3>
                            <span>
                              {day[1]}&nbsp;
                              {day[2]}
                            </span>
                          </div>
                          <div className="event-rightbar mb-4">
                            <h5
                              title={item.title}
                              className="cursor-pointer event-title text-ellipsis"
                            >
                              {item.title}
                            </h5>
                            <p className="no-margin">
                              {moment(item.start).format('DD MMMM YYYY')} {' -  '}
                              {moment(item.end).format('DD MMMM YYYY')}
                            </p>
                            <p className=" events-location event-title text-ellipsis">
                              {item.location}
                            </p>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SaarcEvents;
