import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy | SAARC Bazaar" />
      <Layout>
        <div className="main-content">
          {/* Start Events Area  */}
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 mt_dec--30">
                <div className="static-page">
                  <h3 className="text-center">Privacy Policy</h3>
                  <p>
                    As a visitor or registered user of SAARC Bazaar, the collection and usage of
                    your personal data is subject to the following Terms & Conditions, which have to
                    be agreed by users while visiting and registering on SAARC Bazaar. This policy
                    may change without notice so please ensure that you check the latest version
                    periodically.
                  </p>
                  <h4>What data do we collect?</h4>
                  <ol>
                    <li>
                      The information users provide to us directly
                      <ul>
                        <li>All the information in user accounts on SAARC Bazaar.</li>
                      </ul>
                    </li>
                    <li>
                      Hidden Data
                      <ul>
                        <li>
                          We also collect information that is recorded when users browse and visit
                          our website.
                        </li>
                      </ul>
                    </li>
                    <li>Correspondence occurring between users and SAARC Bazaar</li>
                    <li>Data from secondary sources</li>
                    <li>Data from our own sources</li>
                  </ol>
                  <p>To visit SAARC Bazaar your browser should be configured to accept cookies.</p>
                  <h4>Why do we collect data?</h4>
                  <ul>
                    <li>To ensure that users may be reached by potential trading partners</li>
                    <li>To tailor content as per user needs</li>
                    <li>Update users on our services </li>
                    <li>Respond to requests, queries and concerns</li>
                    <li>Notify users about changes or updates</li>
                    <li>
                      Identify, research and analyze patterns and trends. SAARC Bazaar reserves the
                      right to share the results with its partners.
                    </li>
                  </ul>
                  <p>
                    <b>Note:</b> Information such as company name and contact information is visible
                    to registered users, hence exercise caution when deciding to release any
                    information that is identifiable to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End Events Area  */}
        </div>
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
